import React from 'react';
import "./Blogs.css";
import { Collapse } from 'antd';
import { Link } from 'react-router-dom';
import b1_img from '../../Assets/b1/img1.jpg';
import b2_img from '../../Assets/b2/img1.jpg';
import b3_img from '../../Assets/b3/img1.jpg';
import b4_img from '../../Assets/b4/img1.jpg';
import b5_img from '../../Assets/b5/img1.jpg';
import b6_img from '../../Assets/b6/img1.jpg';
import b7_img from '../../Assets/b7/img1.jpg';
import b8_img from '../../Assets/b8/img1.jpg';
import b9_img from '../../Assets/b9/img1.jpg';
import b10_img from '../../Assets/b10/img1.jpg';
import b11_img from '../../Assets/b11/img1.jpg';
import b12_img from '../../Assets/b12/img1.jpg';
import b13_img from '../../Assets/b13/img1.jpg';
import b14_img from '../../Assets/b14/img1.jpg';
import b15_img from '../../Assets/b15/img1.jpg';
import b16_img from '../../Assets/b16/img1.jpg';
import b17_img from '../../Assets/b17/img1.jpg';
import b18_img from '../../Assets/b18/img1.jpg';
import b19_img from '../../Assets/b19/img1.jpg';
import b20_img from '../../Assets/b20/img1.jpg';
import b21_img from '../../Assets/b21/img1.jpg';
import b22_img from '../../Assets/b22/img1.jpg';
import b23_img from '../../Assets/b23/img1.jpg';
import b24_img from '../../Assets/b24/img1.jpg';
import b25_img from '../../Assets/b25/mytruns.jpg';
import b26_img from '../../Assets/b26/mytguardians.JPG';
import b27_img from '../../Assets/b27/upc1.jpg';
import b28_img from '../../Assets/b28/aws2.jpg';
import b29_img from '../../Assets/b29/eleventhanniv1.jpg';
import b30_img from '../../Assets/b30/cover page-min.jpg';
import b31_img from '../../Assets/b31/the-nun.jpg';
import b32_img from '../../Assets/b32/uphill-1.jpg';
import b33_img from '../../Assets/b33/intellicare33.jpg';
import b34_img from '../../Assets/b34/EMR plus.png';
import b35_img from '../../Assets/b35/heroshot.jpg';
import b36_img from '../../Assets/b36/heroshot.jpg';
import b37_img from '../../Assets/b37/heroshot.jpg';
import b38_img from '../../Assets/b38/heroshot.jpg';
import b39_img from '../../Assets/b39/heroshot.png';
import b40_img from '../../Assets/b40/heroshot.jpg';
import b41_img from '../../Assets/b41/hei.jpg';
import b42_img from '../../Assets/b42/women1.jpg';
import b43_img from '../../Assets/b43/west.jpg';
import b44_img from '../../Assets/b44/leap1.jpg';
import b45_img from '../../Assets/b45/award.jpg';


function Blogs() {
  const { Panel } = Collapse;

  return (
    <>
      {/* <div></div> */}
      <div className='container blog mt-5'>
        <div className='row'>
          <div className='col-4'>
            <img src={b45_img} className='thumbnail b-img' />
          </div>
          <div className='col blogcard-text pt-2 px-4 pb-2'>
            <h2>Kudos, Sir Deson! 🥈</h2>
            <p>Congratulations to Deson G. Maquilang, our esteemed Project Manager, 
              who has been awarded the prestigious Silver Medal in the 2024 Justice Sotero B. Cabahug Medal for Academic Excellence</p>
            <Link to="/blogs/blog45"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
          </div>
        </div>
      </div>

      <div className='container blog mt-5'>
        <div className='row'>
          <div className='col-4'>
            <img src={b44_img} className='thumbnail b-img' />
          </div>
          <div className='col blogcard-text pt-2 px-4 pb-2'>
            <h2>Just Leap!🏃🏻</h2>
            <p>The team took a LEAP to new heights during the Kick-Off, 
              where we charted new paths to success and leaped together beyond boundaries!</p>
            <Link to="/blogs/blog44"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
          </div>
        </div>
      </div>

      <div className='container blog mt-5'>
        <div className='row'>
          <div className='col-4'>
            <img src={b43_img} className='thumbnail b-img' />
          </div>
          <div className='col blogcard-text pt-2 px-4 pb-2'>
            <h2>Sharing is Caring: Design Process using Figma</h2>
            <p>It was an honor to have been invited by West Visayas State University-Himamaylan City Campus - School of Information & Communications Technology
              to share our knowledge on the Design Process using Figma. </p>
            <Link to="/blogs/blog43"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
          </div>
        </div>
      </div>

      <div className='container blog mt-5'>
        <div className='row'>
          <div className='col-4'>
            <img src={b42_img} className='thumbnail b-img' />
          </div>
          <div className='col blogcard-text pt-2 px-4 pb-2'>
            <h2>Happy International Women's Day! 💜 </h2>
            <p>Today, we honor and celebrate every woman who is achieving their goals, overcoming challenges,
              and serving as a role model, especially in the tech industry. ✨</p>
            <Link to="/blogs/blog42"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
          </div>
        </div>
      </div>

      <div className='container blog mt-5'>
        <div className='row'>
          <div className='col-4'>
            <img src={b41_img} className='thumbnail b-img' />
          </div>
          <div className='col blogcard-text pt-2 px-4 pb-2'>
            <h2>KUNG HEI FAT CHOI</h2>
            <p>Entering the Year of the Dragon, we leap forward with prosperity and fruitful collaborations in business.
              Wishing all ventures abundance and opportunities! 🐉</p>
            <Link to="/blogs/blog41"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
          </div>
        </div>
      </div>

      <div className='container blog mt-5'>
        <div className='row'>
          <div className='col-4'>
            <img src={b40_img} className='thumbnail b-img' />
          </div>
          <div className='col blogcard-text pt-2 px-4 pb-2'>
            <h2>LEAP CAMP: Take the First Step <br /> "2024 Pre-kickoff Session"</h2>
            <p>As we LEAP INTO POSSIBILITIES this 2024, the team will collectively LEAP UP and confidently LEAP FORWARD 🚀
              We LEAP INTO THE FUTURE with shared aspirations and growth 🌱</p>
            <Link to="/blogs/blog40"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
          </div>
        </div>
      </div>

      <div className='container blog mt-5'>
        <div className='row'>
          <div className='col-4'>
            <img src={b39_img} className='thumbnail b-img' />
          </div>
          <div className='col blogcard-text pt-2 px-4 pb-2'>
            <h2>MYT Sinulog</h2>
            <p>MYT team takes the streets of Sinulog, celebrating the spirit of camaraderie, shared victories, and a colorful journey ahead 🎉🎶</p>
            <Link to="/blogs/blog39"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
          </div>
        </div>
      </div>

      <div className='container blog mt-5'>
        <div className='row'>
          <div className='col-4'>
            <img src={b38_img} className='thumbnail b-img' />
          </div>
          <div className='col blogcard-text pt-2 px-4 pb-2'>
            <h2>New Year Celebration</h2>
            <p>Ready to conquer new challenges, embrace fresh opportunities, and make every moment count this 2024! 💪✨</p>
            <Link to="/blogs/blog38"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
          </div>
        </div>
      </div>

      <div className='collapse-container'>
        <Collapse>
          <Panel className="h6 blogcard-text" header="2023" key="1">
            <div className='container blog mt-5'>
              <div className='row'>
                <div className='col-4'>
                  <img src={b37_img} className='thumbnail b-img' />
                </div>
                <div className='col blogcard-text pt-2 px-4 pb-2'>
                  <h2>MYT's Yuletide Year-Ender: When in Boracay!</h2>
                  <p>Elevating the holiday spirit with our incredible team – Merry Christmas from MYT Family to yours! 🎉🎄</p>
                  <Link to="/blogs/blog37"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
                </div>
              </div>
            </div>

            <div className='container blog mt-5'>
              <div className='row'>
                <div className='col-4'>
                  <img src={b36_img} className='thumbnail b-img' />
                </div>
                <div className='col blogcard-text pt-2 px-4 pb-2'>
                  <h2>Internship Fair</h2>
                  <p>Module Zero Internship Program offers an opportunity for students to learn the crucial steps into the Tech Industry💙</p>
                  <Link to="/blogs/blog36"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
                </div>
              </div>
            </div>

            <div className='container blog mt-5'>
              <div className='row'>
                <div className='col-4'>
                  <img src={b35_img} className='thumbnail b-img' />
                </div>
                <div className='col blogcard-text pt-2 px-4 pb-2'>
                  <h2>Every Peso Counts</h2>
                  <p>Every peso counts — a reminder that even the smallest financial decisions can have a significant impact on our long-term goals.</p>
                  <Link to="/blogs/blog35"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
                </div>
              </div>
            </div>

            <div className='container blog mt-5'>
              <div className='row'>
                <div className='col-4'>
                  <img src={b34_img} className='thumbnail b-img' />
                </div>
                <div className='col blogcard-text pt-2 px-4 pb-2'>
                  <h2>Paper Medical Records: Are they really reliable?</h2>
                  <p>“Health recording remains to be manual in developing countries like the Philippines.”
                    Open to learn more about EMR?  You can book a demo with us to learn more about MedEase' EMR+.</p>
                  <Link to="/blogs/paper-medical-records"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
                </div>
              </div>
            </div>

            <div className='container blog mt-5'>
              <div className='row'>
                <div className='col-4'>
                  <img src={b33_img} className='thumbnail b-img' />
                </div>
                <div className='col blogcard-text pt-2 px-4 pb-2'>
                  <h2>Intellicare; Health Insurance and Coverage</h2>
                  <p>At MYT, we value the health and well-being of our employees,
                    and ensuring that they are well-informed and covered is important to us. </p>
                  <Link to="/blogs/blog33"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
                </div>
              </div>
            </div>

            <div className='container blog mt-5'>
              <div className='row'>
                <div className='col-4'>
                  <img src={b32_img} className='thumbnail b-img' />
                </div>
                <div className='col blogcard-text pt-2 px-4 pb-2'>
                  <h2>Epic Climb: Uphill Challenge 2023, Rain or Shine</h2>
                  <p>Challenge accepted! 🏃‍♂️🏃‍♀️ We were thrilled to take on the UPHill Challenge 2023, rain or shine! 🌦️
                    Also, congratulations to Coach John, our HIIT Coach, for winning 3rd place! 🥳</p>
                  <Link to="/blogs/blog32"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
                </div>
              </div>
            </div>

            <div className='container blog mt-5'>
              <div className='row'>
                <div className='col-4'>
                  <img src={b31_img} className='thumbnail b-img' />
                </div>
                <div className='col blogcard-text pt-2 px-4 pb-2'>
                  <h2>Fear Unleashed: The MYT Team vs. 'The Nun'</h2>
                  <p>Leveling up our team-building activity with a spine-chilling twist
                    as we bravely gathered to watch 'The Nun'. Team bonding at its spookiest! 👻</p>
                  <Link to="/blogs/blog31"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
                </div>
              </div>
            </div>

            <div className='container blog mt-5'>
              <div className='row'>
                <div className='col-4'>
                  <img src={b30_img} className='thumbnail b-img' />
                </div>
                <div className='col blogcard-text pt-2 px-4 pb-2'>
                  <h2>Our Progess, Your Success!</h2>
                  <p>At the heart of our journey lies our unwavering commitment to delivering the best systems and services,  offering you state-of-the-art
                    software solutions. </p>
                  <Link to="/blogs/blog30"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
                </div>
              </div>
            </div>

            <div className='container blog mt-5'>
              <div className='row'>
                <div className='col-4'>
                  <img src={b29_img} className='thumbnail b-img' />
                </div>
                <div className='col blogcard-text pt-2 px-4 pb-2'>
                  <h2>MYT: A decade plus one of evolution and growth!</h2>
                  <p>Join us as we mark another milestone at MYT SoftDev Solutions, Inc. For the past 11 years, we've been on an incredible journey of growth and transformation, constantly adapting and evolving in the fast-paced world of technology.</p>
                  <Link to="/blogs/blog29"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
                </div>
              </div>
            </div>

            <div className='container blog mt-5'>
              <div className='row'>
                <div className='col-4'>
                  <img src={b28_img} className='thumbnail b-img' />
                </div>
                <div className='col blogcard-text pt-2 px-4 pb-2'>
                  <h2>Unveiling the power-packed partnership</h2>
                  <p>Our company meeting with AWS is all about elevating our server services to new heights of excellence and state of the art infrastructure.</p>
                  <Link to="/blogs/blog28"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
                </div>
              </div>
            </div>

            <div className='container blog mt-5'>
              <div className='row'>
                <div className='col-4'>
                  <img src={b27_img} className='thumbnail b-img' />
                </div>
                <div className='col blogcard-text pt-2 px-4 pb-2'>
                  <h2>Breaking New Grounds: Uniting Forces for Innovation!</h2>
                  <p>We are happy and excited to announce our exciting academic partnership with the University of the Philippines Cebu. Together, we're revolutionizing the way industry and academia collaborate to drive progress, inspire breakthroughs, and shape a brighter future. 🎓</p>
                  <Link to="/blogs/blog27"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
                </div>
              </div>
            </div>

            <div className='container blog mt-5'>
              <div className='row'>
                <div className='col-4'>
                  <img src={b26_img} className='thumbnail b-img' />
                </div>
                <div className='col blogcard-text pt-2 px-4 pb-2'>
                  <h2>MYT meets the Guardians of the Galaxy</h2>
                  <p>Just like the Guardians, our team knows the power of unity and collaboration. We're ready to conquer any development challenge that comes our way. </p>
                  <Link to="/blogs/blog26"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
                </div>
              </div>
            </div>

            <div className='container blog mt-5'>
              <div className='row'>
                <div className='col-4'>
                  <img src={b25_img} className='thumbnail b-img' />
                </div>
                <div className='col blogcard-text pt-2 px-4 pb-2'>
                  <h2>A run for a cause</h2>
                  <p>Run for Gift of Life this May in support of this great cause. Together, we're building stronger bonds while making a difference in the lives of others. Let's run, inspire, and support the gift of life!  ❤️</p>
                  <Link to="/blogs/blog25"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
                </div>
              </div>
            </div>

            <div className='container blog mt-5'>
              <div className='row'>
                <div className='col-4'>
                  <img src={b24_img} className='thumbnail b-img' />
                </div>
                <div className='col blogcard-text pt-2 px-4 pb-2'>
                  <h2>Interhigh Competition</h2>
                  <p>MYT SoftDev Solutions and UP Computer Science Guild rocked the coding world during the Interhighschool Programming Competition, and the highlights are here to blow your mind! 🚀</p>
                  <Link to="/blogs/blog24"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
                </div>
              </div>
            </div>

            <div className='container blog mt-5'>
              <div className='row'>
                <div className='col-4'>
                  <img src={b23_img} className='thumbnail b-img' />
                </div>
                <div className='col blogcard-text pt-2 px-4 pb-2'>
                  <h2>Baking Bonds: Mixing Team Spirit and Creativity!</h2>
                  <p>Our company's team building activit, who knew that measuring ingredients and baking them ourselves could be this much fun?  🍰🧁</p>
                  <Link to="/blogs/blog23"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
                </div>
              </div>
            </div>

            <div className='container blog mt-5'>
              <div className='row'>
                <div className='col-4'>
                  <img src={b22_img} className='thumbnail b-img' />
                </div>
                <div className='col blogcard-text pt-2 px-4 pb-2'>
                  <h2>Summer in MYT</h2>
                  <p>🌞☀️ Soaking up the sun and good vibes in MYT!
                    Our company summer outing has been a blast as we embarked on an incredible journey,
                    creating unforgettable moments as a team that will last a lifetime. 🌊🎉</p>
                  <p><a href='https://www.facebook.com/hashtag/summerinmyt' target='_blank' className='blog-link'></a>
                  </p>
                  <Link to="/blogs/blog22"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
                </div>
              </div>
            </div>

            <div className='container blog mt-5'>
              <div className='row'>
                <div className='col-4'>
                  <img src={b21_img} className='thumbnail b-img' />
                </div>
                <div className='col blogcard-text pt-2 px-4 pb-2'>
                  <h2>Sa Ngalan Sa Amahan</h2>
                  <p>"Explore new possibilities. What is the norm, might not be the good."
                    A story that shed light on societal issues; a play of sadness, laughter, and tears
                    presented by the UP Students' Theater Arts Guild for Education (UPSTAGE) of UP Cebu.
                  </p>
                  <Link to="/blogs/blog21"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
                </div>
              </div>
            </div>

            <div className='container blog mt-5'>
              <div className='row'>
                <div className='col-4'>
                  <img src={b20_img} className='thumbnail b-img' />
                </div>
                <div className='col blogcard-text pt-2 px-4 pb-2'>
                  <h2>Women in Tech</h2>
                  <p>Hi, Tin here 👩‍🚀 </p>
                  <p>Happy International Women's Day! 💜
                    Today is a celebration for every woman out there who's reaching their goals, conquering milestones,
                    and being role models in society especially in technology ✨
                  </p>
                  <Link to="/blogs/blog20"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
                </div>
              </div>
            </div>

            <div className='container blog mt-5'>
              <div className='row'>
                <div className='col-4'>
                  <img src={b19_img} className='thumbnail b-img' />
                </div>
                <div className='col blogcard-text pt-2 px-4 pb-2'>
                  <h2>SM2SM Run</h2>
                  <p>The #MYTTeam is crossing again the finish line stronger than ever before by joining the SM2SM Run,
                    held at SM Seaside to SM City Cebu. 🏃‍♂️🏅</p>
                  <Link to="/blogs/blog19"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
                </div>
              </div>
            </div>

            <div className='container blog mt-5'>
              <div className='row'>
                <div className='col-4'>
                  <img src={b18_img} className='thumbnail b-img' />
                </div>
                <div className='col blogcard-text pt-2 px-4 pb-2'>
                  <h2>Team Building</h2>
                  <p>We may slip and fall, but we must not forget to enjoy this experience with our companions. Down we go with courage, even with all the twists and turns, on this slide of life. </p>
                  <Link to="/blogs/blog18"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
                </div>
              </div>
            </div>

            <div className='container blog mt-5'>
              <div className='row'>
                <div className='col-4'>
                  <img src={b17_img} className='thumbnail b-img' />
                </div>
                <div className='col blogcard-text pt-2 px-4 pb-2'>
                  <h2>7 Eleven Run 2023</h2>
                  <p> Ready, Set, Go! The #MYTTeam is on the move, fueling our healthy habits at the 7-Eleven Run!
                    Time to chase that adrenaline rush! 💪🏁🏃‍♂️🏃‍♀️ </p>
                  <Link to="/blogs/blog17"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
                </div>
              </div>
            </div>

            <div className='container blog mt-5'>
              <div className='row'>
                <div className='col-4'>
                  <img src={b16_img} className='thumbnail b-img' />
                </div>
                <div className='col blogcard-text pt-2 px-4 pb-2'>
                  <h2>MYT Kick-off 2023</h2>
                  <p>MYT is evolving to a customer-centric business to achieve sustainable profitable growth through employee empowerment. </p>
                  <Link to="/blogs/blog16"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
                </div>
              </div>
            </div>

            <div className='container blog mt-5'>
              <div className='row'>
                <div className='col-4'>
                  <img src={b15_img} className='thumbnail b-img' />
                </div>
                <div className='col blogcard-text pt-2 px-4 pb-2'>
                  <h2>Cebu Marathon 2023</h2>
                  <p>The #MYTTeam started the year by being fit 🏃‍♀️🏃‍♂️. Our team were among the thousands who passed through the Cebu-Cordova Link Expressway (CCLEX) for the Cebu Marathon 2023. </p>
                  <Link to="/blogs/blog15"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
                </div>
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>

      <div className='collapse-container'>
        <Collapse>
          <Panel className="h6 blogcard-text" header="2022" key="1">
            <div className='container blog mt-5'>
              <div className='row'>
                <div className='col-4'>
                  <img src={b14_img} className='thumbnail b-img' />
                </div>
                <div className='col blogcard-text pt-2 px-4 pb-2'>
                  <h2>Season of Giving</h2>
                  <p>Tis the season to be jolly and to give back! 🎄
                    MYT celebrates Christmas with UP Cebu Liadlaw Dormers as the company turned over three brand new computers to the dormers. 💻
                  </p>
                  <Link to="/blogs/blog14"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
                </div>
              </div>
            </div>

            <div className='container blog mt-5'>
              <div className='row'>
                <div className='col-4'>
                  <img src={b13_img} className='thumbnail b-img' />
                </div>
                <div className='col blogcard-text pt-2 px-4 pb-2'>
                  <h2>Christmas Party 2022</h2>
                  <p>Merry Yuletide at the Top! Wishing you a season filled with joy, love, and all the warmth of the holiday spirit. 🎄✨❄️🎁🔔🎅🏻</p>
                  <Link to="/blogs/blog13"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
                </div>
              </div>
            </div>

            <div className='container blog mt-5'>
              <div className='row'>
                <div className='col-4'>
                  <img src={b12_img} className='thumbnail b-img' />
                </div>
                <div className='col blogcard-text pt-2 px-4 pb-2'>
                  <h2>November Dump</h2>
                  <p>In MYT, it is a vital part in our culture to maintain
                    a work-life balance and sharing such precious moments
                    together strengthens our relationship.</p>
                  <Link to="/blogs/blog12"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
                </div>
              </div>
            </div>

            <div className='container blog mt-5'>
              <div className='row'>
                <div className='col-4'>
                  <img src={b11_img} className='thumbnail b-img' />
                </div>
                <div className='col blogcard-text pt-2 px-4 pb-2' style={{ padding: '0', margin: '0' }}>
                  <h2>Financial Talk: Retirement Plan</h2>
                  <p>When we reach retirement age, we enter a new and exciting phase of our lives. It's the time to create more memories and pursue the goals we might have missed from our younger years.</p>
                  <Link to="/blogs/blog11"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
                </div>
              </div>
            </div>

            <div className='container blog mt-5'>
              <div className='row'>
                <div className='col-4'>
                  <img src={b10_img} className='thumbnail b-img' />
                </div>
                <div className='col blogcard-text pt-2 px-4 pb-2'>
                  <h2>Upskilling Session 1: Design Process Using Figma</h2>
                  <p>October 19 - MYT joins the UP Computer Science Guild in upskilling
                    talented young designers at the University of the Philippines
                    - Cebu. Tin, a Technical Project Manager of the company, attended
                    as a speaker of the event and discussed to young designers the
                    skills of Design Process in Figma.</p>
                  <Link to="/blogs/blog10"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
                </div>
              </div>
            </div>

            <div className='container blog mt-5'>
              <div className='row'>
                <div className='col-4'>
                  <img src={b9_img} className='thumbnail b-img' />
                </div>
                <div className='col blogcard-text pt-2 px-4 pb-2'>
                  <h2>MYT Opens its New Office</h2>
                  <p>Ten bulbs to celebrate 10 years - these bulbs perfectly represent
                    what happens daily in the office - nurturing ideas, creativity, and a
                    continuous thirst for knowledge. 💡</p>
                  <Link to="/blogs/blog9"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
                </div>
              </div>
            </div>

            <div className='container blog mt-5'>
              <div className='row'>
                <div className='col-4'>
                  <img src={b8_img} className='thumbnail b-img' />
                </div>
                <div className='col blogcard-text pt-2 px-4 pb-2'>
                  <h2>Welcoming of New Scholars</h2>
                  <p>We welcome our new high school and college scholars! 👏 Aside from
                    the full scholarship, they also received brand new laptops to help
                    them with their studies.</p>
                  <Link to="/blogs/blog8"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
                </div>
              </div>
            </div>

            <div className='container blog mt-5'>
              <div className='row'>
                <div className='col-4'>
                  <img src={b7_img} className='thumbnail b-img' />
                </div>
                <div className='col blogcard-text pt-2 px-4 pb-2'>
                  <h2>Intellicare: MYT Benefits Orientation</h2>
                  <p>We would like to extend our gratitude to Intellicare for hosting an
                    orientation about their Health Insurance Coverage and Benefits last
                    August 3, 2022.</p>
                  <Link to="/blogs/blog7"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
                </div>
              </div>
            </div>

            <div className='container blog mt-5'>
              <div className='row'>
                <div className='col-4'>
                  <img src={b6_img} className='thumbnail b-img' />
                </div>
                <div className='col blogcard-text pt-2 px-4 pb-2'>
                  <h2>Accounting and Tax 101: Short Course for Small Business Owners</h2>
                  <p>Any business has a financial side to keep them up and running--and
                    MYT Solutions is not an exception. Not only do we aim to provide better
                    software solutions, but we also care about our company's financial
                    position.</p>
                  <Link to="/blogs/blog6"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
                </div>
              </div>
            </div>

            <div className='container blog mt-5'>
              <div className='row'>
                <div className='col-4'>
                  <img src={b5_img} className='thumbnail b-img' />
                </div>
                <div className='col blogcard-text pt-2 px-4 pb-2'>
                  <h2>Front-End Development Workshop</h2>
                  <p>Gone are the days when developers have to code full, multipage
                    applications. 😰 Referring to a front-end style guide can speed up this process — ✨ A LOT ✨.</p>
                  <Link to="/blogs/blog5"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
                </div>
              </div>
            </div>

            <div className='container blog mt-5'>
              <div className='row'>
                <div className='col-4'>
                  <img src={b4_img} className='thumbnail b-img' />
                </div>
                <div className='col blogcard-text pt-2 px-4 pb-2'>
                  <h2>MYT is now NPC-Certified!</h2>
                  <p>We are now certified with the National Privacy Commission. Providing solutions, as well as protecting your data,
                    complied with the international standards set for data protection through the NPC.
                  </p>
                  <Link to="/blogs/blog4"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
                </div>
              </div>
            </div>

            <div className='container blog mt-5'>
              <div className='row'>
                <div className='col-4'>
                  <img src={b3_img} className='thumbnail b-img' />
                </div>
                <div className='col blogcard-text pt-2 px-4 pb-2'>
                  <h2>Training on Docker and Python</h2>
                  <p>We would like to extend our gratitude to Mr. Rommel Paras, a Senior
                    Systems Engineer of Accel Robotics who led the training program on
                    Docker and Python last April 5 & 7, 2022.</p>
                  <Link to="/blogs/blog3"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
                </div>
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>

      <div className='collapse-container'>
        <Collapse>
          <Panel className="h6 blogcard-text" header="2021" key="1">
            <div className='container blog mt-5'>
              <div className='row'>
                <div className='col-4'>
                  <img src={b2_img} className='thumbnail b-img' />
                </div>
                <div className='col blogcard-text pt-2 px-4 pb-2'>
                  <h2>Distributing Water Bottles to Typhoon Odette Victims</h2>
                  <p>Today, the MYT Softdev Solutions and ErrandBoy.PH team, family, and
                    friends were able to give out almost 300 distilled water and mineral
                    water bottles to families, children, and senior citizens at The
                    Greenery, Cebu City, Cebu.</p>
                  <Link to="/blogs/blog2"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
                </div>
              </div>
            </div>

            <div className='container blog mt-5 mb-5'>
              <div className='row'>
                <div className='col-4'>
                  <img src={b1_img} className='thumbnail b-img' />
                </div>
                <div className='col blogcard-text pt-2 px-4 pb-2'>
                  <h2>MYT SoftDev Solutions: Innovative. Intuitive. Powerful.</h2>
                  <p>MYT Softdev Solutions is a global community of developers, designers,
                    and analysts with the vision to create solutions to showcase the
                    competitive edge of our clients for better productivity, profitability
                    and sustainability.</p>
                  <Link to="/blogs/blog1"><button type='button' className='btn btn-primary btn-blog'>Read More</button></Link>
                </div>
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>
    </>
  );
}

export default Blogs;