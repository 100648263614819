import React from 'react'
import "./BlogPost.css";

import award from "../../Assets/b45/award.jpg";

function Blog42() {
    return (
        <div className='container-fluid my-5'>
            <div className='row mb-5'>
                <div className='col blog-text text-center'>
                    <h1>Kudos, Sir Deson! 🥈 </h1>
                <h6>April 24, 2024 at 10:01 AM</h6>
                </div>
            </div>

            <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
                    <div id="blogImages" className="carousel carousel-dark slide mx-auto" data-bs-ride="true" data-bs-interval='4000'>
                    <div className="carousel-indicators">
                    </div>    
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={award} className="blog-img" alt="..." />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
                    <p>Congratulations to Deson G. Maquilang, our esteemed Project Manager, who has been awarded the prestigious
                        Silver Medal in the 2024 Justice Sotero B. Cabahug Medal for Academic Excellence. </p>
                    <p>This award not only recognizes Deson's outstanding academic excellence but also highlights his exceptional leadership qualities,
                        which have been instrumental in our team's success. </p>
                    <p>Deson, your dedication and commitment to excellence inspire us all. Congratulations on this well-deserved recognition!👏</p>
                </div>
            </div>
        </div>
    )
}

export default Blog42
